import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useController } from 'react-hook-form';

import { useGetStoreInfo } from '../../../../globals';
import { getDecimalCount } from '../../../../utils/currencyUtils';
import { stringToFloat } from '../../../../utils/mathUtils';
import './Currency.css';

export function MultipleCurrencyInput({
  allowNegativeValue,
  control,
  id,
  isRequired,
  label,
  name,
  readOnly,
  supportText,
  supportTextColor,
}) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    defaultValue: '',
    name,
  });

  const [formattedValue, setFormattedValue] = useState(value);

  useEffect(() => {
    setFormattedValue(value);
  }, [value]);

  const textFieldProps = {
    id,
    label,
    readOnly,
    supportText,
  };

  const { currency, locale } = useGetStoreInfo();

  return (
    <TextFieldStyled
      shouldMakeActive
      ariaLive="polite"
      error={!!error}
      errorMessage={error?.message}
      requiredIndicator={isRequired ? '*' : ''}
      slots={{
        InputSlot: (
          <CurrencyInput
            allowDecimals
            allowNegativeValue={allowNegativeValue}
            className="currency-input"
            decimalsLimit={getDecimalCount()}
            defaultValue={value}
            disableAbbreviations
            disabled={readOnly}
            id={id}
            intlConfig={currency && locale ? { currency, locale } : {}}
            name={name}
            value={formattedValue}
            onBlur={onBlur}
            onValueChange={(newValue) => {
              if (!newValue) {
                setFormattedValue(0);
                onChange(0);
              } else {
                setFormattedValue(newValue);
                onChange(stringToFloat(newValue));
              }
            }}
          />
        ),
      }}
      supportTextProps={{ style: { color: supportTextColor } }}
      type="text"
      {...textFieldProps}
    />
  );
}

MultipleCurrencyInput.defaultProps = {
  allowNegativeValue: false,
  isRequired: false,
  readOnly: false,
  supportText: '',
  supportTextColor: '',
};

MultipleCurrencyInput.propTypes = {
  allowNegativeValue: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  supportText: PropTypes.string,
  supportTextColor: PropTypes.string,
};
